body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.calendar-container {
  width: 100%;
  border-radius: 12px;
  overflow: scroll;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background: white;
  margin-bottom: 20px;
  border-radius: 12px 12px 0 0;
}

.calendar-header .title {
  font-size: 20px;
  font-weight: bold;
  color: #171a1c;
}

.calendar-header .navigation {
  display: flex;
  align-items: center;
}

.calendar-header .nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 40px;
  font-size: 14px;
  cursor: pointer;
  margin: 0 10px;
}

.calendar-header .nav-button:hover {
  background-color: #0056b3;
}

.calendar-header .month {
  font-size: 16px;
  font-weight: bold;
  color: #171a1c;
  min-width: 100px;
  text-align: center;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #fff;
  /* border-right: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  border-bottom: 0.5px solid #e0e0e0; */
}

.day-header {
  padding: 12px;
  text-align: center;
  font-weight: bold;
  background-color: #efefef;
  position: sticky;
  top: 35px;
  z-index: 3;
  color: #171a1c;
}

.day-cell {
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  background-color: white;
  padding: 16px;
  min-width: 120px;
  height: 100px;
  margin: 4px;
  box-sizing: border-box;
  position: relative;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
  display: flex;
}

.day-cell.empty {
  background-color: #f9f9f9;
}

.day-cell:hover {
  /* background-color: #f0f4ff; */
  cursor: pointer;
}
.date {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 90%;
}
.date-day {
  font-size: 20px;
}
.date-status {
  padding-top: 4px;
  font-size: 8px;
}
.status {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0px;
  text-align: center;
  border-left: 3px solid;
  padding-left: 6px;
}
.day-cell .date {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  position: absolute;
  top: 8px;
  left: 8px;
}

.day-cell .time {
  font-size: 12px;
  color: #333;
  margin-top: 4px;
  text-align: center;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 10px;
  /* border: 1px solid #eee; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); */
  padding-top: 30px;
  font-size: 16px;
  line-height: 24px;
  color: #555;
  /* background-color: white; */
}
.title {
  font-size: 45px;
  color: #171a1c;
}

.invoice-box table {
  width: 100%;
  min-width: 600px;
  min-height: 150px;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}
.invoice-box .heading {
  height: 40px;
}
.invoice-information {
  float: right;
}
.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.invoice-box button:hover {
  background-color: #45a049;
}

.project-name {
  display: flex;
  justify-content: center;
  margin-top: 6px;
  font-size: 14px;
  color: #171a1c;
}

@media (max-width: 600px) {
  .calendar-grid {
    grid-template-columns: repeat(2, 1fr); /* Adjust to fit smaller screens */
  }
}
