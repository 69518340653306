/* Branding Section */
.brand {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #f39c12;
  letter-spacing: 2px;
  margin-top: 30px;
}

.address-bar {
  text-align: center;
  font-size: 1.4rem;
  color: #7f8c8d;
  margin-top: 10px;
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Flexbox Grid */
.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* Box Section */
.box {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 30px;
  width: 100%;
  max-width: 900px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.box:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.15);
}

/* Responsive Columns */
.col-lg-12 {
  flex-basis: 100%;
}

/* Text Alignment */
.text-center {
  text-align: center;
}

/* Image Styling */
.img-responsive {
  border-radius: 15px;
  max-width: 100%;
  height: auto;
  margin-bottom: 25px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.img-responsive:hover {
  transform: scale(1.05);
}

/* Headings */
h1,
h2 {
  color: #2c3e50;
  font-weight: 600;
}

.brand-before small,
.brand-name {
  color: #e67e22;
  font-size: 1.8rem;
}

.brand-name {
  font-size: 4rem;
}

.tagline-divider {
  border: none;
  height: 2px;
  background-color: #e67e22;
  width: 200px;
  margin: 20px auto;
}

/* Intro Text */
.intro-text {
  color: #333;
}

.intro-text strong {
  color: #3498db;
}

/* Contact Section */
p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

p strong {
  color: #34495e;
}

a {
  color: #e67e22;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
  color: #d35400;
}

/* Footer */
footer {
  background-color: #34495e;
  padding: 30px 0;
  color: #fff;
}

footer .container {
  max-width: 1200px;
}

footer .row {
  text-align: center;
}

footer .copyright {
  font-size: 1rem;
  margin-top: 10px;
}

/* Transitions and Effects */
button,
a {
  transition: all 0.3s ease;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .brand {
    font-size: 2.5rem;
  }

  .address-bar {
    font-size: 1.1rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1rem;
  }

  .container {
    padding: 15px;
  }
}
